/*
Inflexer Face: frontend of Inflexer

__author__ = 'Taeyang Yang (tyami@naver.com)'
__copyright__ = 'Copyright (C) 2024-, Taeyang Yang. All rights reserved.'
*/
import React from 'react';
import { Collapse } from 'react-collapse';

const Filters = ({
  isOpen,
  setIsOpen,
  mediaFilters,
  handleCheckboxChange,
  pointFilters,
}) => {
  return (
    <div>
      <Collapse isOpened={isOpen}>
        <section className="filters">
          <div className="filter_media" id="filter_media">리뷰 매체</div>
          <label><input type="checkbox" value="BP_" checked={mediaFilters.includes('BP_')} onChange={() => handleCheckboxChange('media', 'BP_')} />블로그 포스팅</label>
          <label><input type="checkbox" value="IP_" checked={mediaFilters.includes('IP_')} onChange={() => handleCheckboxChange('media', 'IP_')} />인스타 포스팅</label>
          <label><input type="checkbox" value="YP_" checked={mediaFilters.includes('YP_')} onChange={() => handleCheckboxChange('media', 'YP_')} />유튜브 포스팅</label>
          <br/>
          <label><input type="checkbox" value="BC_" checked={mediaFilters.includes('BC_')} onChange={() => handleCheckboxChange('media', 'BC_')} />블로그 클립</label>
          <label><input type="checkbox" value="IR_" checked={mediaFilters.includes('IR_')} onChange={() => handleCheckboxChange('media', 'IR_')} />인스타 릴스</label>
          <label><input type="checkbox" value="YS_" checked={mediaFilters.includes('YS_')} onChange={() => handleCheckboxChange('media', 'YS_')} />유튜브 쇼츠</label>

          <div className="filter_point" id="filter_point">포인트 지급</div>
          <label><input type="checkbox" value="O" checked={pointFilters.includes('O')} onChange={() => handleCheckboxChange('point', 'O')} />O</label>
          <label><input type="checkbox" value="X" checked={pointFilters.includes('X')} onChange={() => handleCheckboxChange('point', 'X')} />X</label>

      </section>
      </Collapse>
      <button onClick={() => setIsOpen(!isOpen)} id="filter_expansion">
        {isOpen ? '⬆️ 검색조건 숨기기 ⬆️' : '⬇️ 검색조건 펼치기 ⬇️'}
      </button>
    </div>
  );
};

export default Filters;