import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { API_ENDPOINT_RELOAD } from '../constants.js';

const AppAdmin = () => {
  const [message, setMessage] = useState('');
  const [inputCode, setInputCode] = useState('');

  const reloadData = async () => {
    if (inputCode !== 'reload') {
      setMessage('Invalid code. Please try again.');
      return;
    }

    setMessage('Runnnig...');

    try {
      const response = await fetch(`${API_ENDPOINT_RELOAD}`, { method: 'POST' });
      const data = await response.json();
      if (data.status === 'success') {
        setMessage('Runned successfully!');
      } else {
        setMessage('Failed to run code: ' + data.message);
      }
    } catch (error) {
      setMessage('Error: ' + error.message);
    }
  };

  return (
    <div className="container">
      <h1>INFLEXER admin</h1>
      <section className="content">
        <input
          type="text" 
          value={inputCode} 
          onChange={(e) => setInputCode(e.target.value)} 
          placeholder="Enter code"
        />
        <button onClick={reloadData}>Submit</button>
        <div>{message}</div>
        <Link to="/">Go back to Home</Link>
      </section>
      <section className="footer">
        <div className='ads_coopang_footer'>
          <aside>
            <div className="content-padding"></div>
            <div className="coupang_banner">
              <iframe 
                title="coupang_banner"
                src="https://ads-partners.coupang.com/widgets.html?id=786057&template=carousel&trackingCode=AF2025463&subId=inflexer01&width=2000&height=120&tsource=" 
                width="100%"
                height="120"
                frameborder="0" 
                style={{ border: 'none', overflow: 'hidden', position: 'fixed', bottom: '12px', left: '0', zIndex: '1000' }}
                scrolling="no" 
                referrerpolicy="unsafe-url" 
                browsingtopics>
              </iframe>
            </div>
            <div style={{ position: 'fixed', bottom: '0', left: '0', width: '100%', textAlign: 'center', zIndex: '1001', background: 'white', fontSize: '8px'}}>
              이 포스팅은 쿠팡 파트너스 활동의 일환으로, 이에 따른 일정액의 수수료를 제공받습니다.
            </div>
          </aside>
        </div>
      </section>
        </div>
  );
};

export default AppAdmin;