/*
Inflexer Face: frontend of Inflexer

__author__ = 'Taeyang Yang (tyami@naver.com)'
__copyright__ = 'Copyright (C) 2024-, Taeyang Yang. All rights reserved.'
*/
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppMain from './Main/Main.js';
import AppAdmin from './Admin/Admin.js';
import AppPress from './Press/Press.js';
import AppShipping from './Shipping/Shipping.js';
import { SHOW_NOTICE, SHOW_BANNER_AD, SHOW_FOOTER_AD, USE_CHANNEL_TALK } from './configs.js';

import * as ChannelService from '@channel.io/channel-web-sdk-loader';

const components = {
  Main: AppMain,
  Press: AppPress,
  Shipping: AppShipping,
};

const AppWrapper = ({ page, showBannerAd, showFooterAd }) => {
  const Component = components[page];

  useEffect(() => {
    if (USE_CHANNEL_TALK) {
      ChannelService.loadScript();
      ChannelService.boot({
        pluginKey: "ba88c312-c437-417a-8509-0f2531af9d12" });
    }
  }, []);

  return (
    <Component showNotice={SHOW_NOTICE} showBannerAd={showBannerAd} showFooterAd={showFooterAd} />
  );
};

const AppWithRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AppWrapper page={"Main"} showBannerAd={SHOW_BANNER_AD} showFooterAd={SHOW_FOOTER_AD}/>} />
        <Route path="/noad" element={<AppWrapper page={"Main"} showBannerAd={false} showFooterAd={false} />} />
        <Route path="/press" element={<AppWrapper page={"Press"} showBannerAd={SHOW_BANNER_AD} showFooterAd={SHOW_FOOTER_AD} />} />
        <Route path="/shipping" element={<AppWrapper page={"Shipping"} showBannerAd={SHOW_BANNER_AD} showFooterAd={SHOW_FOOTER_AD} />} />
        <Route path="/admin" element={<AppAdmin />} />
      </Routes>
    </Router>
  );
};

export default AppWithRouter;