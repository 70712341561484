// import GoogleAdSense from '../common/GoogleAdsense.js';

// <div className="ads_google_banner">
// <GoogleAdSense 
//   client="ca-pub-7971368984672974" 
//   slot="8845447829" 
//   style={{ display: 'block' }} 
//   format="auto" 
//   responsive="true" 
// />
// </div>
import React, { useEffect, useRef } from 'react';

function GoogleAdSense({ client, slot, style, format, responsive }) {
    const adElementRef = useRef(null);

    useEffect(() => {
        // 구글 애드센스 스크립트를 동적으로 삽입
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
        script.setAttribute("crossorigin", "anonymous");
        adElementRef.current.appendChild(script);

        // 광고를 초기화
        script.onload = () => {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        };

        return () => {
            if (adElementRef.current) {
                adElementRef.current.innerHTML = '';
            }
        };
    }, [client, slot]);

    return (
        <div ref={adElementRef} style={{ width: '100%', textAlign: 'center' }}>
            <ins className="adsbygoogle"
                style={{ display: 'block', ...style }}
                data-ad-client={client}
                data-ad-slot={slot}
                data-ad-format={format}
                data-full-width-responsive={responsive}></ins>
        </div>
    );
}

export default GoogleAdSense;
