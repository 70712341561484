/* 
Inflexer Face: frontend of Inflexer

__author__ = 'Taeyang Yang (tyami@naver.com)'
__copyright__ = 'Copyright (C) 2024-, Taeyang Yang. All rights reserved.'
*/

export const SHOW_NOTICE = true;

export const NEW_NOTICE = true;
export const NEW_OPINION = false;

export const SHOW_BANNER_AD = true;
export const SHOW_FOOTER_AD = false;
export const USE_CHANNEL_TALK = false;

export const DEBUG = false;
